import { Component, OnInit } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { HomeService } from '../../service/home.service';
import { AuthService } from '../../service/auth.service';
import { UserIdleService } from 'angular-user-idle';
import { TranslateService } from '@ngx-translate/core';
import { EncodedJwtToken } from "../../model/AuthResource";
import { StorageService } from '../../service/storage.service';
import { DemandesService } from '../../service/demandes.service';


@Component({
  selector: 'app-header-pro',
  templateUrl: './header-pro.component.html',
  styleUrls: ['./header-pro.component.scss']
})
export class HeaderProComponent implements OnInit {

  /**
   * Available languages
   */
  langs: any;
  currentLang: string;
  encodedJwtToken : EncodedJwtToken;
  organisationId:any;
  messageList:any;

  constructor(private router: Router, 
    private homeService: HomeService,
    private demandesService: DemandesService,
    private authService: AuthService,
    private userIdle: UserIdleService,
    private storageService: StorageService,
    private translateService: TranslateService) {  
      this.langs = translateService.getLangs();
  }

  ngOnInit() { 
    this.currentLang = this.translateService.currentLang;
    this.encodedJwtToken = this.authService.getEncodedJwtToken();

    this.organisationId = this.storageService.getOrganisationId();

    /*this.demandesService.getMessages(this.organisationId).subscribe(messageList => {
      this.messageList = messageList;
      console.log('messageList => ', this.messageList);
    }); */   
    
  }

  disconnect() {   
    this.authService.logout();
    this.userIdle.stopWatching();
    this.router.navigate(['/dashboard/home']);
  }

  isLoggedIn() {
    return this.authService.isLogged();
  }

  isProfile() {
    return this.router.url.startsWith('/p');
  }

  canSeeProfile() {
    //return true;
    /*return this.homeService.getCurrentHome().pipe(map(ret => {
        const home = new Resources(ret);
        return home.getRel('managers');
    }));
    */
  }

  useLanguage(selectedLanguage) {
    this.translateService.use(selectedLanguage);
    this.currentLang = this.translateService.currentLang;
  }

}
