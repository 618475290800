import { Component, OnInit, Input  } from '@angular/core';

@Component({
    selector: 'wizard-steps',
    styleUrls: ['./wizard-steps.component.scss'],
    template: `<div class="wizard wizard-steps" style="display: flex;">
    <div class="steps clearfix">
       <ul role="tablist">
            <li role="tab" class="done" *ngFor="let item of stepsArray;let index = index; let first = first; let last = last" [ngClass]="currentClass(index)">
                <a id="wizard-t-0" href="#wizard-h-0" aria-controls="wizard-p-0"></a>
            </li>           
       </ul>
    </div>
    <h4 class="m-0 text-center pt-3"><ng-content></ng-content></h4>
 </div>`
})
export class WizardStepsComponent implements OnInit {

    @Input() activeStep: number;
    stepsArray: Array<any>;

    constructor() { }
    ngOnInit() { 
    }
    
    private _steps: number;
    
    @Input() set steps(value: number) {    
        this._steps = value;
        // create
        this.stepsArray = [];
        for (let index = 0; index < this._steps; index++) {
            this.stepsArray.push({ value: index })            
        }        
    }
    
    get steps(): number {    
        return this._steps;    
    }

    currentClass(stepIndex:number) {
        console.info('StepIndex ', stepIndex);
        console.info('activeStep ', this.activeStep);
        if (this.activeStep > stepIndex) 
            return "first checked current";
        else 
            return "last done";
    }

}