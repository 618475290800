import { Component, OnInit } from '@angular/core';
import { RegisterService } from '../service/register.service';
import { AccountForm, AccountDealerForm,  } from '../model/rest/AccountForm';
@Component({
  selector: 'app-registerdealer',
  templateUrl: './registerdealer.component.html',
  styleUrls: ['./registerdealer.component.scss']
})
export class RegisterdealerComponent implements OnInit {
  accountForm : AccountDealerForm = new AccountDealerForm();
  submitted = false;

  constructor(private registerService : RegisterService){}
 
  ngOnInit() {
   /* this.accountForm = {
      firstname : "naruto",
      lastname : "sasuke",
      email : "sasuke03@gmail.com",
      password: "password1234567",
      birthdate: "1976-12-11",
      phoneNumber : "123456780",
      accountType : "SELLER"
    };*/
  }

  private save() {
  
    console.log('AccountForm ', this.accountForm);


    this.registerService.createAccount(this.accountForm).subscribe(data =>console.log(data),error => console.log(error));

  }
  
  
  onSubmit() {
    this.submitted = true;
    this.save();
  }
 

}
