import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { mergeMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { DemonstrationData } from '../model/DemonstrationData';

/**
 * 
 */
@Injectable({
  providedIn: 'root'
})
export class DemoDataService {

  constructor(private httpClient: HttpClient, private router: Router) {
  }
  
}
