import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { mergeMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { StorageService } from '../service/storage.service';
import { DemoDataService } from '../service/data.service';

import { environment } from '../../environments/environment';
import { AuthResource, EncodedJwtToken } from '../model/AuthResource';

/**
 * 
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private urlApi:string = environment.baseURLAPI + 'api/account';

  constructor(private httpClient: HttpClient, 
    private router: Router, 
    private storageService: StorageService,
    private demoDataService: DemoDataService) {
  }

  /*
  environment.baseURLAPI + home.getRel('auth')
  */
  authUser(email, password): Observable<AuthResource> {

    let headers = new HttpHeaders()
      .append('Authorization','Basic ' + btoa( environment.clientId + ':' + environment.secret));

    let httpOptions = {
      headers: headers
    };

    const params = new HttpParams().append('grant_type','password')
      .append('username',email)
      .append('password',password);

    return this.httpClient.post( this.urlApi + '/oauth', params, httpOptions)
      .pipe(
        map((res: any) => {
          return new AuthResource({token:res.access_token, expiresIn:res.expires_in, accountId: res.accountId, accountEmail: res.user_name, organisationId: res.organisationId});
        })
      );
  }

  /**
   * 
   */
  validateAccount(email): Observable<boolean> {
    const params = new HttpParams().append('email',email);
    let httpOptions = {
      params: params
    };
    return this.httpClient.get( this.urlApi + '/activate', httpOptions)
      .pipe(
        map((res: any) => {            
          return true;
        })
      );
  }

  decodeToken(token: string): EncodedJwtToken {
    var jwtToken = JSON.parse(atob(token.split(".")[1]));
    this.storageService.saveToken(token, jwtToken.exp, jwtToken.accountId, jwtToken.user_name
        , jwtToken.organisationId, jwtToken.organisationName);
    return new EncodedJwtToken(jwtToken);
  }

  getEncodedJwtToken(): EncodedJwtToken {
    if (this.isLogged()) {
      var jwtToken = JSON.parse(atob(this.storageService.getToken().split(".")[1]));
      return new EncodedJwtToken(jwtToken);
    }
    return null;
  }

  isLogged(): boolean {
    return !!this.storageService.getToken();
  }

  logout() {
    this.storageService.clearToken();
  }

}
