import { Component, OnInit } from '@angular/core';
import { RegisterService } from '../../service/register.service';

@Component({
  selector: 'app-registerhome',
  templateUrl: './registerhome.component.html',
  styleUrls: ['./registerhome.component.scss']
})
export class RegisterHomeComponent implements OnInit {
 

  constructor(private registerService : RegisterService){}
 
  ngOnInit() {
  }
}
