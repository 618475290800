import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgModel } from "@angular/forms";

import { HomeService } from '../service/home.service';
import { StorageService } from '../service/storage.service';
import { AuthService } from '../service/auth.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent{  
  partnerValue: string;
  passwordValue: string;
  returnUrl: string;
  errMessage: string;
  otherError = false;
  credentialsError = false;
  loggedIn = false;
  hasResetRel = true;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private homeService: HomeService,
    private authService: AuthService,
    private storageService: StorageService){
    
     }
  
  
  connectTo(partner: string) {
    this.storageService.savePartner(partner);
    this.router.navigate(['/auth']);
  }

}
