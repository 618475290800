import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { StorageService } from '../../service/storage.service';
import { GestionService } from '../../service/gestion.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-rightbar',
  templateUrl: './rightbar.component.html',
  styleUrls: ['./rightbar.component.scss']
})
export class RightBarComponent implements OnInit {

  rightbarForm: FormGroup;
  gestionList: any;

  constructor(private router: Router, 
    private formBuilder: FormBuilder, 
    private storageService: StorageService, 
    private gestionService: GestionService) { }

  ngOnInit() {
    this.gestionService.getGestions().subscribe(gestionList => {
      this.gestionList = gestionList;
      console.log('gestionList => ', this.gestionList);
    }); 
  }

  onGestionSelected(){
    this.storageService.updateGestion(this.rightbarForm.value.gestionId);
    this.router.navigate(['/dashboard/admin']);
  }

}
