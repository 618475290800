import { Component, OnInit, ElementRef, ViewChild, HostListener } from '@angular/core';

@Component({
    selector: 'ud-dropdown',
    template: `
    <div class="dropdown is-right" #dropdown>
    <div class="dropdown-trigger">
      <button class="button is-link is-rounded" aria-haspopup="true" aria-controls="dropdown-menu6" (click)="onClick($event)">
        <span>Right aligned</span>
        <span class="icon is-small">
          <i class="fas fa-angle-down" aria-hidden="true"></i>
        </span>
      </button>
    </div>
    <div class="dropdown-menu" id="dropdown-menu6" role="menu">
      <div class="dropdown-content">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
    `
})
export class UdDropdownComponent implements OnInit {
    
    @ViewChild("dropdown") divDropdown: ElementRef;

    constructor(private eRef: ElementRef) {
    }

    ngOnInit() {}
    
    onClick($event:any): void {
        this.divDropdown.nativeElement.classList.toggle('is-active');
    }

    @HostListener('document:click', ['$event'])
    clickout(event) {
      if(this.eRef.nativeElement.contains(event.target)) {        
      } else {
        this.divDropdown.nativeElement.classList.remove('is-active');
      }
    }
  
    
}