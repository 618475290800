import { Injectable } from '@angular/core';

import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { GestionForm} from '../model/rest/GestionForm';
import { AuthResource } from '../model/AuthResource';

const TOKEN_KEY = 'APP_TOKEN_KEY';
const ACCOUNT_ID_KEY = 'ACCOUNT_ID_KEY';
const ORGANISATION_ID_KEY = 'ORGANISATION_ID_KEY';
const PARTNER_KEY = 'APP_PARTNER_KEY';
const EXPIRES_IN = 'EXPIRES_IN';
const GESTION_ID_KEY = 'GESTION_ID_KEY';
const ACCOUNT_USERNAME_KEY = 'ACCOUNT_USERNAME_KEY';
const ORGANISATION_NAME_KEY = 'ORGANISATION_NAME_KEY';
const LOGO_KEY = 'LOGO_KEY';

/**
 * Service servant à gérer la sessionStorage.
 */
@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private router: Router) { }

  // Suppression de toutes les données du sessionStorage.
  public clearAll() {
    sessionStorage.clear();
  }

  // Sauvegarde du token d'authentification à l'api.
  public saveToken(token: string, expiresIn: string, 
        accountId: string, accountEmail: string, 
        organisationId: string , organisationName: string) {
    this.clearToken();
    sessionStorage.setItem(TOKEN_KEY, token);
    sessionStorage.setItem(ACCOUNT_ID_KEY, accountId);
    sessionStorage.setItem(ORGANISATION_ID_KEY, organisationId);
    sessionStorage.setItem(EXPIRES_IN, expiresIn);
    sessionStorage.setItem(ACCOUNT_USERNAME_KEY, accountEmail);
    sessionStorage.setItem(ORGANISATION_NAME_KEY, organisationName);
    //sessionStorage.setItem(LOGO_KEY, organisationLogo);
  }

  // update du temps d'expiration du token
  public updateExpiresIn(expiresIn: number) {
    sessionStorage.setItem(EXPIRES_IN, expiresIn.toString());
  }

  // Récupération du token d'authentification à l'api.
  public getToken(): string {
    return sessionStorage.getItem(TOKEN_KEY);
  }

  // Récupération du token d'authentification à l'api.
  public getAccountId(): string {
    return sessionStorage.getItem(ACCOUNT_ID_KEY);
  }

  // Récupération du token d'authentification à l'api.
  public getOrganisationId(): string {
    return sessionStorage.getItem(ORGANISATION_ID_KEY);
  }

  // Récupération du mail d'authentification à l'api.
  public getAccountEmail(): string {
    return sessionStorage.getItem(ACCOUNT_USERNAME_KEY);
  }

  public getExpiresTime(): number {
    return Number(sessionStorage.getItem(EXPIRES_IN));
  }

  // Suppression du token d'authentification à l'api (deconnection)
  public clearToken() {
    sessionStorage.removeItem(TOKEN_KEY);
    sessionStorage.removeItem(ACCOUNT_ID_KEY);
    sessionStorage.removeItem(EXPIRES_IN);
    sessionStorage.removeItem(ACCOUNT_USERNAME_KEY);
    sessionStorage.removeItem(ORGANISATION_ID_KEY);
    sessionStorage.removeItem(ORGANISATION_NAME_KEY);
    //sessionStorage.removeItem(LOGO_KEY);
  }

  // Sauvegarde du nom du partenaire.
  public savePartner(partner: string) {
    this.clearPartner();
    sessionStorage.setItem(PARTNER_KEY, partner);
  }

  // Récupération du nom du partenaire.
  public getPartnerName(): string {
    return sessionStorage.getItem(PARTNER_KEY);
  }

  //Suppression du nom du partenaire de la session.
  public clearPartner() {
    sessionStorage.removeItem(PARTNER_KEY);
  }

  public getProcedurePid() {
    return null;
  }

  public getGestionId() {
    return sessionStorage.getItem(GESTION_ID_KEY);
  }

  // update du temps d'expiration du token
  public updateGestion(gestionId: string) {
    sessionStorage.setItem(GESTION_ID_KEY, gestionId);
  }

}
