
/**
 * Authentication resource
 */
export class AuthResource {
  token: string;
  expiresIn: string;
  accountId: string | null;
  accountEmail: string | null;
  organisationId: string | null;
  organisationName: string | null;
  organisationLogo: any;
  
  constructor(init: any) {
    this.token = init ? init.token : '';
    this.expiresIn = init ? init.expiresIn : '0'; 
    this.accountId = init ? init.accountId : null; 
    this.organisationId = init ? init.organisationId : null;
    this.organisationName = init ? init.organisationName : null;
    this.organisationLogo = init ? init.organisationLogo : null;
    this.accountEmail = init ? init.user_name : null; 
  }

}




/**
 * Jwt Encoded Object
 */
export class EncodedJwtToken {
  accountId: number;
  authorities: Array<string>;
  email: string;
  username: string;
  organisationId: number;
  organisationName: string;
  organisationLogo: string;
  accountType: AccountType;

  constructor(jwtToken: any) {
    this.accountId = jwtToken ? jwtToken.accountId : '';
    this.authorities = jwtToken ? jwtToken.authorities : []; 
    this.organisationId = jwtToken ? jwtToken.organisationId : '';
    this.organisationName = jwtToken ? jwtToken.organisationName : '';
    this.organisationLogo = jwtToken ? jwtToken.organisationLogo : '';
    this.email = jwtToken ? jwtToken.user_name : null; 
    this.username = jwtToken && jwtToken.nom ? `${jwtToken.prenom} ${jwtToken.nom}` : 'User UNDEFINED';
    this.accountType = jwtToken && jwtToken.accountType ? jwtToken.accountType : AccountType.ANONYMOUS;
  }

}

export enum AccountType {
  PRO  = "PRO",
	PART = "PART", 
	ANONYMOUS = "ANONYMOUS",
}
