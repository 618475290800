import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sellers-says',
  templateUrl: './sellers-says.component.html',
  styleUrls: ['./sellers-says.component.scss']
})
export class SellersSaysComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
