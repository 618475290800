import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { mergeMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { StorageService } from '../service/storage.service';
import { DemoDataService } from '../service/data.service';

import { environment } from '../../environments/environment';
import { AuthResource } from '../model/AuthResource';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { OfferForm} from '../model/rest/OfferForm';

import { IndexedOffer} from '../model/rest/Offer';



/**
 * 
 */
@Injectable({
  providedIn: 'root'
})
export class OfferSrevice {

  private urlApi:string = 'http://localhost:8083';

  constructor(private httpClient: HttpClient, 
    private router: Router, 
    private storageService: StorageService,
    private demoDataService: DemoDataService,
    private _sanitizer: DomSanitizer) {
  }

  /*P
  environment.baseURLAPI + home.getRel('auth')
  */
  createOffer(offerForm:OfferForm): Promise<string>{

    console.info(offerForm);

    var formData = new FormData();
 
    // The FormData object provides a way to programmatically submit data that the
    // Browser could have natively submitted using a "<form/>" tag. Each entry here
    // represents a form-control field.

   formData.append( "longname", offerForm.longname );
    formData.append( "shortname", offerForm.shortname );
    formData.append( "description", offerForm.description );
    formData.append( "price", offerForm.price.toString() );
    formData.append( "currency", offerForm.currency );
    formData.append( "category.reference", offerForm.category.reference);
    formData.append( "visibility", offerForm.visibility );
    formData.append( "offerstatus", offerForm.offerstatus );

    // While the above values are "simple" values, we can add File Blobs to the
    // FormData in the exactly same way.
    // --
    // NOTE: An optional "filename" can be provided for Files. But, for this demo,
    // we're going to allow the native filename to be used for the uploads.
    ( offerForm.image ) && formData.append( "image", offerForm.image );

    return this.httpClient.post('http://localhost:8083/api/offer', formData)
      .pipe(
        map((res: any) => {
          return res;
        })
      ).toPromise();
  }


  getOffers(accountId: string): Observable<any> {
    const url = `${this.urlApi}/api/offer/${accountId}`;
    return this.httpClient.get(url);
  }

  /**
   * Return all offer for the current connected user
   */
  getOffersForCurrentUser(): Promise<any> {
    const url = `${this.urlApi}/api/offer/list`;
    return this.httpClient.get(url).pipe(
      map((res: any) => {
        return res;
      })
    ).toPromise();
  }

  getMainImage(offer:any) : string {
    return offer.images && offer.images.length ? `http://localhost:8083/api/offer/image/${offer.images[0].id}` : "http://via.placeholder.com/565x565.jpg";
  }

  getMainImageByOffer(indexedOffer:IndexedOffer) : string {
    return `http://localhost:8083/api/offer/image/main/${indexedOffer.offerId}`;
  }

  /*
  getImage(offer: any): {
    let url = `http://localhost:8083/api/offer/image/${offer.images[0].id}`;
    return this.httpClient.get(url, {responseType: 'blob'}).pipe(
      map(blob => {
      var urlCreator = window.URL;
      return this._sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(blob));
      })
    );
  }
  */

}
