
import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { OfferSrevice } from '../../service/offer.service';
import { CategoryService } from '../../service/category.service';
import { OfferForm, OfferStatus } from '../../model/rest/OfferForm';
import { NgModel } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { from } from 'rxjs';
import { Category } from 'src/app/model/rest/Category';
import { CurrencyIndex } from '@angular/common/src/i18n/locale_data';

import { TranslateService } from '@ngx-translate/core';
import { NgForm } from '@angular/forms/src/directives/ng_form';


@Component({
  selector: 'app-createoffer',
  templateUrl: './createoffer.component.html',
  styleUrls: ['./createoffer.component.scss']
})
export class CreateofferComponent implements OnInit {
  
  pageErrorMessage : { display: boolean, message : string} = { display: false, message : '' };
  submitInProgress: boolean = false;
  offerCreated: boolean = false;

  public form : {
    longname: string,
    shortname: string,
    description: string,
    price: null,
    currency: "EUR",
    category: "",
    image: FileList | null,
    visibility: "PUBLIC" | "PRIVATE",
    offerstatus: OfferStatus.DISABLED
  };

  public categories: Array<Category>;
  private selectedCategory: string;

  constructor(private offerService: OfferSrevice, private categoryService: CategoryService, private translateService: TranslateService) {
    this.categoryService.getAllCategoriesReference().subscribe( res => {
      this.categories = res;
    }); 

  }

  public submitForm($form): void {

    var longname = this.form.longname;
    var shortname = this.form.shortname;
    var description = this.form.description;
    var price = this.form.price;
    var currency = this.form.currency;
    var category = { reference : this.form.category} ;
    var visibility = this.form.visibility;
    var offerstatus = this.form.offerstatus;
    var image = (this.form.image && this.form.image.length)
      ? this.form.image[0]
      : null;

    this.offerCreated = false;
    this.submitInProgress = true;

    this.offerService.createOffer({
      longname: longname,
      shortname: shortname,
      description: description,
      price: price,
      currency: currency,
      category:category ,
      image: image,
      visibility: visibility,
      offerstatus: offerstatus

    })
      .then(
        () => {
          this.offerCreated = true;
          this.clearForm($form);
        },
        (error) => {
          console.error(error);
          this.pageErrorMessage = { display: true, message : error.message};
        }
      ).then( () => {
        this.submitInProgress = false;
        
      });
      
  }

  ngOnInit() {
    this.clearForm();
  }

  changeSelectedCategory($event) {
    this.selectedCategory = $event.target.value;
  }

  getImageName(image) {
   return (image && image.length) ? this.form.image[0].name : this.translateService.instant('offer.create.upload_image');
  }

  clearError() {
    this.pageErrorMessage = { display: false, message : ''};
  }

  clearMessage() {
    this.offerCreated = false;
  }

  clearForm($form?: NgForm) {
    if ($form) {
      $form.resetForm();
    }

    this.form = {
      longname: "",
      shortname: "",
      description: "",
      price: null,
      currency:"EUR",
      category: "",
      image: null,
      visibility: "PUBLIC",
      offerstatus: OfferStatus.DISABLED
    };

    this.clearError();
  }

}
