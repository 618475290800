import { FormGroup } from '@angular/forms';

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }
        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}


export function numberValidator(number): any {
    if (number.pristine) {
       return null;
    }
    const NUMBER_REGEXP = /^-?[\d.]+(?:e-?\d+)?$/;
    number.markAsTouched();
    if (NUMBER_REGEXP.test(number.value)) {
       return null;
    }
    return {
       invalidNumber: true
    };
 }

export function passwordValidator(password): any {
    if (password.pristine) {
       return null;
    }
    const password_REGEXP = /^(?=.*[0-9]).{8,15}$/;
    password.markAsTouched();
    if (password_REGEXP.test(password.value)) {
       return null;
    }
    return {
       invalidPassword: true
    };
 }