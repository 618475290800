import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { NgModel, NgForm } from "@angular/forms";



import { HomeService } from '../service/home.service';
import { StorageService } from '../service/storage.service';
import { AuthService } from '../service/auth.service';
import { DemoDataService } from '../service/data.service'
import { IdleService } from '../service/idle.service'

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  emailValue: string ;
  userValue: string = '';
  passwordValue: string;
  emailDemoValue: string;
  clientNameValue: string;
  returnUrl: string;
  errMessage: string;
  otherError = false;
  credentialsError = false;
  loggedIn = false;
  hasResetRel = true;
  authenticationInProgress = false;
  submitted = false;
  oauthForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private homeService: HomeService,
    private authService: AuthService,
    private storageService: StorageService,
    private demoDataService: DemoDataService,
    private idleService: IdleService) { }

  ngOnInit() {

    this.oauthForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required ]],  /* , Validators.pattern('^(?=.*[0-9]).{8,15}$')*/
    });

  }

  onSubmit($event) {

    this.submitted = true;
    
    // stop here if form is invalid
    if (this.oauthForm.invalid) {
      console.error('Error!! :-)\n\n' + JSON.stringify(this.oauthForm.value))
      return;
    }

    this.doAuth($event);

  }

  doAuth($event) {

    $event.preventDefault();
    this.authenticationInProgress = true;

    this.authService.logout();
    this.otherError = false;
    this.credentialsError = false;

    this.authService.authUser(this.oauthForm.value.email, this.oauthForm.value.password).subscribe(resource => {
      console.info('AuthResource ', resource);

      //this.recupererLogoOrganisation(resource.organisationId);

      
      // Sauvegarde le token et redirige vers la page de listing des démarches
      this.storageService.saveToken(resource.token, resource.expiresIn, 
          resource.accountId, this.oauthForm.value.email, 
          resource.organisationId, resource.organisationName);
      this.loggedIn = true;
      this.router.navigate(['/dashboard/admin']);
      this.authenticationInProgress = false;

      this.idleService.startWatch();
    },
    err => {
      if(err.status === 401) {
        this.credentialsError = true;
      } else {
        this.otherError = true;
        this.errMessage = err.message;
      }
      this.authenticationInProgress = false;
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.oauthForm.controls; }
  
  logout() {
    this.authService.logout();
    this.router.navigate(['/']);
  }

  preventDefault($event) {
    $event.target.disabled = true;
  }
}
