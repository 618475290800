import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { Router, ActivatedRoute, RouterOutlet } from '@angular/router';
import { NgModel } from "@angular/forms";

import { find, filter, pick, cloneDeep } from "lodash";

import { HomeService } from '../service/home.service';
import { StorageService } from '../service/storage.service';
import { AuthService } from '../service/auth.service';
import { StateService } from '../service/state.service';
import { DemarchesService } from '../service/demarches.service';

import { Procedure } from '../model/rest/Procedure';
import { fadeAnimation } from '../animations'

import { domSetClipboard } from '../utils/ClipboardUtils'

import { DocumentDemarche } from '../model/rest/DocumentDemarche';
import { ProcedureStatus, QualifyProcedureInput, RefusedDocument } from '../model/rest/Qualification';
import { BehaviorSubject } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-demarches',
  templateUrl: './demarches.component.html',
  styleUrls: ['./demarches.component.scss'],
  animations: [
    fadeAnimation
  ]
})
export class DemarchesComponent implements OnInit {  

  partnerValue: string;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private storageService: StorageService) { }

  ngOnInit() {
    this.partnerValue = this.storageService.getPartnerName();
  }

  showList(){
    this.router.navigate(['/demarches/list']);
  }

  addDemarche() {
    this.router.navigate(['/demarches/create']);
  }

  stats() {
    this.router.navigate(['/demarches/stats']);
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

}

/**
 * Composant pour la création d'une démarche utilisateur 
 * Appel à l'API user/partial et récupération de l'url de pérsonalisation 
 */
@Component({
  selector: 'app-demarche-create',
  templateUrl: './create/demarche.create.component.html',
  styleUrls: ['./create/demarche.create.component.scss']
})
export class CreateDemarcheComponent implements OnInit {  
  partnerValue: string;
  civilityValue: string = '';
  firstnameValue: string = '';
  lastnameValue: string = '';
  identifierValue: string = '';
  errMessageStatus: string;
  errMessageCode: string;
  errMessageUrl: string;
  restError = false;
  partnerError = false;
  customizationUrl: string;
  copiedPURL = false;
  creationInProgress = false;
  
  constructor(private storageService: StorageService,
    private demarchesService: DemarchesService) { }

  ngOnInit() {
    this.civilityValue = 'MR';
  }

  doCreateUser($event) {
    $event.preventDefault();
    this.creationInProgress = true;
  	this.demarchesService.createUser(this.civilityValue, this.firstnameValue, this.lastnameValue, this.identifierValue).subscribe(resource => {
  	  this.demarchesService.createCustomizationUrl(this.identifierValue).subscribe(resource => {
        this.customizationUrl = resource.customization_url;
        this.creationInProgress = false;
  	  },
	  err => {
	    if (err.status === 409){
	      this.partnerError = true;
	    }else{
	      this.errMessageStatus = err.statusText;
	      this.errMessageCode = err.status;
	      this.errMessageUrl = err.url;
	      this.restError = true;
        }
        this.creationInProgress = false;
	  });
    },
    err => {
      if(err.status === 409){
        this.partnerError = true;
      }else{
        this.errMessageStatus = err.statusText;
        this.errMessageCode = err.status;
        this.errMessageUrl = err.url;
        this.restError = true;
      }
      this.creationInProgress = false;
    });
  }
  
  copyToClipboard(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    inputElement.blur();
  }
}

/**
 * Affiche les statistiques des démarches d'un univers
 */
@Component({
  selector: 'app-demarches-stats',
  templateUrl: './stats/demarches.stats.component.html',
  styleUrls: ['./stats/demarches.stats.component.scss']
})
export class StatsDemarchesComponent implements OnInit {  
  errMessage: string;
  partnerValue: string;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private homeService: HomeService,
    private authService: AuthService,
    private storageService: StorageService) { }

  ngOnInit() {  
    this.partnerValue = 'partner_demo_immo';  
  }

  connectTo(partner: string) {
    this.router.navigate(['/auth'], { queryParams: { partner: partner }});
  }

}

/**
 * Affiche la liste des démarches d'un univers
 */
@Component({
  selector: 'app-demarches-list',
  templateUrl: './list/demarches.list.component.html',
  styleUrls: ['./list/demarches.list.component.scss']
})
export class ListDemarchesComponent implements OnInit {
  errMessageStatus: string;
  errMessageCode: string;
  errMessageUrl: string;
  restError = false;
  partnerValue: string;
  procedures: Procedure[];
  
  constructor(private router: Router,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private demarchesService: DemarchesService,
    private stateService: StateService) { }

  ngOnInit() {  
    this.partnerValue = this.storageService.getPartnerName();
    this.demarchesService.getListDemarches(this.storageService.getProcedurePid()).subscribe(resource => {
      this.procedures = resource.procedures;
      this.stateService.getStateStore().setState({
        demarches: resource
      });
    },
    err => {
      this.errMessageStatus = err.statusText;
      this.errMessageCode = err.status;
      this.errMessageUrl = err.url;
      this.restError = true;
    });
  }

  gotoProcedure(procedureId: string) {
    this.router.navigate([`/demarches/details/${procedureId}`]);
  }

}


/**
 * Affiche la liste des démarches d'un univers
 */
@Component({
  selector: 'app-demarche-details',
  templateUrl: './details/demarche.details.component.html',
  styleUrls: ['./details/demarche.details.component.scss']
})
export class DetailsDemarcheComponent implements OnInit {
  errMessage: string;
  errMessageStatus: string;
  errMessageCode: string;
  errMessageUrl: string;
  partnerValue: string;
  procedure: Procedure;
  documents: DocumentDemarche[];
  restError = false;
  procedureStatus: string;
  loadingText: string;
  isProcRefused: boolean = false;
  isProcClosed: boolean = false;
  documentsStatus: BehaviorSubject<any>;
  refusedDocuments: RefusedDocument[];
  validatedDocuments: string[];
  refuseReasonGeneral: string;
  
  constructor(private router: Router,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private demarchesService: DemarchesService,
    private stateService: StateService) { }

  ngOnInit() {  
    this.initDocuments();
    
    this.stateService.getStateStore().select('qualificationProcedure').subscribe(
      (qualifProcedures) => {
        let demarche = find(qualifProcedures, {userProcedureId: this.route.snapshot.paramMap.get('id')});
        if(demarche){
	        let map = [];
	        demarche.validatedDocumentsId.forEach(id => {
	          map.push({ "documentId" : id, "statut" : "Valid" });
	        });
	        demarche.refused_documents.forEach( refusedDoc => {
	          map.push({ "documentId" : refusedDoc.document_id, "statut" : "Refus" });
	        });
	        this.refusedDocuments = demarche.refused_documents; 
	        this.validatedDocuments = demarche.validatedDocumentsId;
			this.documentsStatus = new BehaviorSubject(map);
	    }
      }
    );
  }
 
  getStatusByDocumentId(docId:string): Observable<string> {
    if(this.documentsStatus){
	    return this.documentsStatus.pipe(
	      map((res: any) => {
	        let documentStatut = find(res, { documentId : docId });
	        if (documentStatut) {
	          return documentStatut.statut;
	        }
	      })
	    );
	 }
	 return null;
  }

  // PUBLIC METHODS
  previewDocument(document: DocumentDemarche) {
    this.stateService.getStateStore().setState({
      document: document
    });
    this.router.navigate([`/demarches/details/document`]);
  }

  refuseProcedure() {
    this.isProcRefused = true;
  }

  closeProcedure() {
    this.isProcClosed = true;
  }

  validateProcedure() {
    this.qualifyProcedure(ProcedureStatus.VALIDATED);
  }

  cancelRefusal() {
    this.isProcRefused = false;
    this.isProcClosed = false;
  }

  sendQualifProcedure() {
    if(this.isProcRefused) {
      this.qualifyProcedure(ProcedureStatus.STARTED);
    }
    if(this.isProcClosed) {
      this.qualifyProcedure(ProcedureStatus.OBSOLETED);
    }
  }
  
  /**
   * Qualification de la procedure
   * @param procedureStatus le statut de la procedure
   */
  qualifyProcedure(procStatus: ProcedureStatus) {

    this.isProcRefused = false;
    this.isProcClosed = false;
    this.loadingText = 'Qualification de la démarche';
	
    let qualifyProcedureInput : QualifyProcedureInput = { 
      status : ProcedureStatus[procStatus],
      refusal_ground: this.refuseReasonGeneral,
      refused_documents: this.refusedDocuments
    };

    this.demarchesService.qualifyProcedure(
      this.procedure.user_procedure_data.user_procedure_id, qualifyProcedureInput).subscribe(ok => {
        this.procedureStatus = ProcedureStatus[procStatus];
        this.stateService.getStateStore().setState({
          qualificationProcedure: null
        });
      },
      err => {
          this.errMessageStatus = err.statusText;
          this.errMessageCode = err.status;
          this.errMessageUrl = err.url;
          this.restError = true;
      }
    );
  }

  // PRIVATE METHODS
  
  /**
   * Filtre les docuements d'une démarche
   */
  private initDocuments(): void {
    
    this.procedureStatus = null;
    this.loadingText = 'Chargement des documents';

    const id = this.route.snapshot.paramMap.get('id');
    
    this.stateService.getStateStore().select('demarches').subscribe(      
      (demarches) => {
        if (demarches === null){
          this.router.navigate(['/demarches/list']);
          throw new Error('Vous devez choisir une démarche.');
        }
        
        this.procedure = find(demarches.procedures, 
          (proc) => proc.user_procedure_data.user_procedure_id == id
        );
 
        this.filterDocuments(this.procedure);
       
      }
    ); 
  }

  private filterDocuments(procedure: Procedure) {
    this.documents = new Array<DocumentDemarche>();
    // Si la procedure contient des documents (asset)
    if (procedure.user_procedure_data.nb_assets_provided > 0) {
      // Get assets
      procedure.user_procedure_data.assets.forEach( asset => {
        if (asset.nb_documents > 0) {
          // Get documents
          asset.assets.forEach( documentAsset => {
            if (documentAsset.nb_documents > 0) {
              documentAsset.assets.forEach(finalDocumentAsset => {
                let document: DocumentDemarche = <DocumentDemarche> {};
                document.title = finalDocumentAsset.title;
                document.docName = documentAsset.title;
                document.docTypeName = asset.title;
                document.docSize = finalDocumentAsset.document_size;       
                document.mimetype = finalDocumentAsset.mimetype;
                document.documentId = finalDocumentAsset.document_id;
                this.demarchesService.getDocumentMetadata(procedure.user_procedure_data.user_procedure_id, document.documentId).subscribe(metadata => {
                  document.certification = metadata.quality;
                });
                document.userProcedureId = procedure.user_procedure_data.user_procedure_id;
                this.documents.push(document);
              });
            }
          });
        }
      });
    }
  }

  // on active le boutton valider uniquement si tous les documents de la demarche ont été validé.
  disableValidateButton(): boolean {
    if(!this.validatedDocuments){
      return true;
    }
  	return !(this.validatedDocuments.length == this.documents.length);
  }
  
  // on desactive le boutton modifier uniquement si tous les documents de la demarche ont été validé.
  disableModificationButton(): boolean {
    if(!this.refusedDocuments){
      return true;
    }
    return !(this.refusedDocuments.length >= 1);
  }
}

@Pipe({name: 'mimetype'})
export class MimetypePipe implements PipeTransform {
  transform(mimetype: string): string {
    return !!mimetype ? mimetype.split("/")[1].toUpperCase() : 'N/C';
  }
}
