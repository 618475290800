import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import { RegisterService } from '../service/register.service';
import { AccountForm, AccountSellerForm } from '../model/rest/AccountForm';

// import custom validator to validate that password and confirm password fields match
import { MustMatch, passwordValidator, numberValidator } from '../utils/util.validator';

@Component({
  selector: 'app-registerseller',
  templateUrl: './registerseller.component.html',
  styleUrls: ['./registerseller.component.scss']
})
export class RegistersellerComponent implements OnInit {
  
  pageErrorMessage : { display: boolean, message : string} = { display: false, message : '' };
  pageMessage : { display: boolean, message : string} = { display: false, message : '' };

  accountForm : AccountSellerForm = new AccountSellerForm();
  submitted = false;

  sellerForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private registerService : RegisterService) {
  }
 
  ngOnInit() {
    this.sellerForm = this.formBuilder.group({
        firstname: ['', [Validators.required, Validators.minLength(2)]],
        lastname: ['', [Validators.required, Validators.minLength(2)]],
        phonenumber: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(14), numberValidator ]],
        birthdate: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.pattern('^(?=.*[0-9]).{8,15}$') ]],
        confirmPassword: ['', Validators.required]
    }, {
        validator: MustMatch('password', 'confirmPassword')
    });
}

  private save() {
    console.log('AccountForm ', this.accountForm);
    this.registerService.createAccount(this.accountForm).subscribe(
      data =>  {
        console.log(data);
        this.pageMessage = { display: true, message : 'Your account is registered.'};
      }
      ,
      error => { 
        console.log(error);
        this.pageErrorMessage = { display: true, message : error.message};
      });
  }
  
  // convenience getter for easy access to form fields
  get f() { return this.sellerForm.controls; }
  
  clearError() {
    this.pageErrorMessage = { display: false, message : ''};
  }

  clearMessage() {
    this.pageMessage = { display: false, message : ''};
  }


  /**
   * Submit the form  
   */
  onSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      if (this.sellerForm.invalid) {
        console.error('Error!! :-)\n\n' + JSON.stringify(this.sellerForm.value))
        return;
      }

      // map form data
      this.accountForm.firstname = this.sellerForm.value.firstname;
      this.accountForm.lastname = this.sellerForm.value.lastname;
      this.accountForm.phoneNumber = this.sellerForm.value.phonenumber;
      this.accountForm.birthdate = this.sellerForm.value.birthdate;
      this.accountForm.email = this.sellerForm.value.email;
      this.accountForm.password = this.sellerForm.value.password; 
      
      // Save the account 
      this.save();
  }

}



