import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ud-dropdown-item',
    template: `
    <div class="dropdown-item">
       <ng-content></ng-content>
    </div>
    `
})
export class UdDropdownItemComponent implements OnInit {
    constructor() { }
    ngOnInit() { }
}