import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import { DynamicAsideMenuConfig, MenuConfig, MenuSectionItem } from '../../core/configs/dynamic-aside-menu.config';

import { AuthService } from '../../service/auth.service';
import { EncodedJwtToken } from "../../model/AuthResource";

import { filter, flatMap } from "lodash";
declare var $: any;

@Component({
    selector: 'app-aside',
    template: `
    <ul class="metismenu side-nav">
      <li class="side-nav-item">
        <a routerLink="/dashboard" class="side-nav-link">
          <i class="uil-home-alt"></i>
          <span> Dashboards </span>
        </a>
      </li>
      <ng-container *ngFor="let item of menuConfig.items">
        <ng-container
          *ngIf="item.title"
          [ngTemplateOutlet]="menu1LevelTemplate"
          [ngTemplateOutletContext]="{ item: item }"
        ></ng-container>
      </ng-container>
    </ul>

    <!-- begin::1 Level -->
    <ng-template #menu1LevelTemplate let-item="item">    
      <li class="side-nav-item" *ngIf="hasSubMenu(item)">
        <a href="javascript: void(0);" class="side-nav-link">
            <i [ngClass]="item.icon"></i>
            <span> {{ item.title }} </span>
            <span class="menu-arrow"></span>
        </a>

        <ng-container *ngIf="item.submenu">
          <ul class="side-nav-second-level" aria-expanded="false">
            <ng-container 
              *ngFor="let sub of item.submenu"
              [ngTemplateOutlet]="menu2LevelTemplate"
              [ngTemplateOutletContext]="{ item: sub }"
            ></ng-container>
          </ul>
        </ng-container>

      </li>
    </ng-template>
    
    <ng-template #menu2LevelTemplate let-item="item">
      <li *ngIf="hasPermission(item)">
        <a [routerLink]="item.page">{{ item.title }}</a>
      </li>
    </ng-template>
    `
})
export class ASideComponent implements OnInit, AfterViewInit {
    
    // @ViewChild("dropdown") divDropdown: ElementRef;
    menuConfig: MenuConfig;
    encodedJwtToken : EncodedJwtToken;

    constructor(private eRef: ElementRef, private authService: AuthService) {
    }

    ngOnInit() {
      this.menuConfig = DynamicAsideMenuConfig;
      this.encodedJwtToken = this.authService.getEncodedJwtToken();      
    }
    
    onClick($event:any): void {
        // this.divDropdown.nativeElement.classList.toggle('is-active');
    }

    @HostListener('document:click', ['$event'])
    clickout(event) {
      if(this.eRef.nativeElement.contains(event.target)) {        
      } else {
        // this.divDropdown.nativeElement.classList.remove('is-active');
      }
    }

    ngAfterViewInit(): void {
      $.LeftSidebar.initMenu();
      $.LeftSidebar.activateDarkTheme();
    }
  
    hasPermission(item: MenuSectionItem): boolean {
      if (item.permission) {              
        return this.encodedJwtToken.authorities.includes(item.permission);
      }
      return true;
    }

    hasSubMenu(item: MenuSectionItem): boolean {
      let permissions = filter(
        flatMap(item.submenu, (itemSubMenu) => itemSubMenu.permission ), 
        (permission) => this.encodedJwtToken.authorities.includes(permission)
      );
      return permissions && permissions.length > 0;
    }

}
