import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgModel } from "@angular/forms";

import { find, filter, pick, cloneDeep } from "lodash";

import { OfferSrevice } from "../service/offer.service";
import { SearchService } from '../service/search.service';
import { StorageService } from '../service/storage.service';
import { AuthService } from '../service/auth.service';
import { Category } from 'src/app/model/rest/Category';
import { CategoryService } from '../service/category.service';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {  

  public categories: Array<Category>;
  originalOffers: Array<any>;
  offers: Array<any>;

  initSearch: boolean = true;
  hasNoOffers: boolean;

  public form : {
    criteria: string,
    price: null,
    category: ""
  };

  constructor(private searchService: SearchService,private offerService: OfferSrevice, private categoryService: CategoryService) {
    this.form = {
      criteria: "",
      price: null,
      category: ""
    };

    this.categoryService.getAllCategoriesReference().subscribe( res => {
      this.categories = res;
    }); 
  }

  ngOnInit() {
  }

  doSearch() {
    this.initSearch = false;
     // Get all offers
    this.searchService.searchOffers(this.form.criteria).then((offers) => {
      console.log(offers);
      this.originalOffers = offers;
      this.filterByCriteria();
    }).catch((err) => {

    });
  }

  filterByCriteria() {
    console.info(this.form);    
    this.offers = filter(this.originalOffers, (o) => {
      return true;
    });
    this.hasNoOffers = this.offers && this.offers.length === 0;
  }

  /**
   *
   *
   */
  getMainImage(offer) {
    return this.offerService.getMainImageByOffer(offer);
  }

}
