import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { StorageService } from '../service/storage.service';

const TOKEN_HEADER_KEY = 'Authorization';
const X_USER_ID = 'X-USER-ID';
const X_USERNAME = 'X-USERNAME';
const X_GESTION_ID = 'X-GESTION-ID';
const X_ORGANISATION_ID ='X-ORGANISATION-ID';

@Injectable()
export class TokenHeaderInterceptor implements HttpInterceptor {

  constructor(private storageService: StorageService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = req;
    const token = this.storageService.getToken();
    if (token !== null) {
        authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
    }
    const accountId = this.storageService.getAccountId();
    if (accountId !== null) {
      authReq = authReq.clone({ headers: authReq.headers.set(X_USER_ID, accountId) });
    }

    const accountEmail = this.storageService.getAccountEmail();
    console.log("accountEmail => " + accountEmail);
    if (accountEmail !== null) {
      authReq = authReq.clone({ headers: authReq.headers.set(X_USERNAME, accountEmail) });
    }

    const gestionId = this.storageService.getGestionId();
    console.log("gestionId => " + gestionId);
    if (gestionId !== null) {
      authReq = authReq.clone({ headers: authReq.headers.set(X_GESTION_ID, gestionId) });
    }    

    const organisationId = this.storageService.getOrganisationId();
    console.log("organisationId => " + organisationId);
    if (organisationId !== null) {
      authReq = authReq.clone({ headers: authReq.headers.set(X_ORGANISATION_ID, organisationId) });
    }  

    return next.handle(authReq);    
  }
}
 
export const TokenHeaderInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: TokenHeaderInterceptor, multi: true }
];
