import { Category } from "./Category";

export interface OfferForm {
   longname : string;
   shortname : string;
   description : string;
   price : number;
   currency : string;
   category: any;
   image: File | null;
   visibility:string;
   offerstatus: OfferStatus;
}

export enum OfferStatus {
   DISABLED = "DISABLED", ENABLED = "ENABLED", PUBLISHED = "PUBLISHED", IN_SALE = "IN_SALE"
}
