import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { mergeMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { GestionForm} from '../model/rest/GestionForm';
import { StorageService } from './storage.service';

/**
 * 
 */
@Injectable({
  providedIn: 'root'
})
export class GestionService {

  private urlApi:string = 'http://localhost:8081/api/configuration';
  private gestionForm: GestionForm;

  constructor(private httpClient: HttpClient, 
    private router: Router, 
    private storageService: StorageService,
    private _sanitizer: DomSanitizer) {
  }


  getGestions(): Observable<any> {
    const url = `${this.urlApi}`;
    return this.httpClient.get(url);
  }

  getCurrentGestion(): Observable<any> {
    const url = `${this.urlApi}/current`;
    return this.httpClient.get(url);
  }

  createGestion(identifiantGestion: string): Promise<string>{
	const url = `${this.urlApi}/ouvrirGestion`;
    console.info(identifiantGestion);

    this.gestionForm = {
      "identifiantGestion": identifiantGestion,
      "operationType": "GESTION_OUVERTE" 
    };
    
    console.log("formData => " + this.gestionForm);
    return this.httpClient.post(url, this.gestionForm)
      .pipe(
      map((res: any) => {
        return res;
      })).toPromise();
  }

}
