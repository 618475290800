import { Component, OnInit, Input } from '@angular/core';


/**
 * See src/assets/css/icon_fonts/demo_icon_set_2.html
 */
@Component({
    selector: 'ud-icon',
    template: `
    <i [ngClass]="iconClass()"></i> <ng-content></ng-content>
    `,
    styleUrls: ['./ud-icon.component.scss']
})
export class UdIconComponent implements OnInit {
    @Input() icon: string;
    constructor() { }
    ngOnInit() { }
    iconClass() {
        return 'pe-7s-' + this.icon;
    }
}