import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, throwError, of } from 'rxjs';
import { mergeMap, map, tap, catchError, shareReplay } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  private urlApi:string = 'http://localhost:8089';

  constructor(private httpClient: HttpClient) {}

  /**
   * Search offers by criteria
   * @param criteria longname
   */
  searchOffers(criteria: string): Promise<any> {
    const url = `${this.urlApi}/api/search/offer?criteria=${criteria}`;
    return this.httpClient.get(url).pipe(
      map((res: any) => {
        return res;
      })
    ).toPromise();
  }

  handleError(operation: string) {
    return (err: any) => {     
      return throwError(err);
    };
  }
  
}
