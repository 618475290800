import { NgModule } from '@angular/core';
import { Routes, RouterModule, NavigationStart, NavigationEnd } from '@angular/router';
import { AuthComponent } from './auth/auth.component';

import { HomeComponent } from './home/home.component';
import { SearchComponent } from './search/search.component';

import { DemarchesComponent, StatsDemarchesComponent, CreateDemarcheComponent, ListDemarchesComponent, DetailsDemarcheComponent } from './demarches/demarches.component';
import {RegisterComponent } from './register/register.component';
import { OfferComponent } from './offer/offer.component';

import { AuthGuard } from './auth/auth.guard';
import { AccountPartGuard, AccountProGuard, PublicGuard } from './commons/guards-module/guards.service';

import { RegistersellerComponent } from './registerseller/registerseller.component';
import { RegisterdealerComponent } from './registerdealer/registerdealer.component';
import { RegisterHomeComponent } from './register/home/registerhome.component';
import { AccountdashbordComponent } from './account/accountdashbord/accountdashbord.component';
import { OfferhomeComponent } from './offer/home/offerhome.component';

import { CreateofferComponent } from './offer/createoffer/createoffer.component'
import { AccountActivationComponent } from './account/account-activation/account-activation.component';

import { LayoutComponent } from "./ui/layout/layout.component";
import { LayoutPrivateComponent } from "./ui/layout/layout-private.component";

const routes: Routes = [
  //Site routes goes here 
  { 
    path: '', redirectTo:'dashboard/home', pathMatch: "full"
  },
  
  {
    path: 'account',
    /*canActivate: [AuthGuard],*/
    loadChildren: () =>
      import('./modules/account/account.module').then(
        (m) => m.AccountModule
      ),
  },
  {
    path: 'demandes',
    /*canActivate: [AuthGuard],*/
    loadChildren: () =>
      import('./modules/demandes/demandes.module').then(
        (m) => m.DemandesModule
      ),
  },
  {
    path: 'processus',
    /*canActivate: [AuthGuard],*/
    loadChildren: () =>
      import('./modules/processus/processus.module').then(
        (m) => m.ProcessusModule
      ),
  },
  {
    path: 'echanges',
    loadChildren: () =>
      import('./modules/echanges/echanges.module').then(
        (m) => m.EchangesModule
      ),
  },

  {
    path: 'declarations',
    /*canActivate: [AuthGuard],*/
    loadChildren: () =>
      import('./modules/declaration/declaration.module').then(
        (m) => m.DeclarationModule
      ),
  },
  
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/settings/settings.module').then(
        (m) => m.SettingsModule
      ),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  { path: 'search', component: SearchComponent, data: {animation: 'HomePage'} },
  { path: 'activateAccount', component: AccountActivationComponent, data: {animation: 'HomePage'} },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
