/**
 * Section item of menu
 */
export interface MenuSectionItem {
  title: string,
  page: string,
  root?: boolean,
  icon?: string,
  svg?: string,
  translate?: string,
  bullet?: string,
  permission?: string,
  submenu?: Array<MenuSectionItem>
}

export interface MenuConfig {
items: Array<MenuSectionItem>;
}

export const DynamicAsideMenuConfig: MenuConfig = {
  items: [
    {
      title: 'Settings',
      root: true,
      icon: 'uil-cog',
      svg: './assets/media/svg/icons/Design/Layers.svg',
      page: '/dashboard',
      translate: 'MENU.DASHBOARD',
      bullet: 'dot',
      submenu: [
        {
          title: 'Ouvrir une gestion',
          page: '/settings/gestion/create',
          permission: 'GESTION'
        },
        {
          title: 'Organisations',
          page: '/settings/organisation/list',
          permission: 'ORGANISATION'
        },
        {
          title: 'Actes réglementaires',
          page: '/settings/actes',
          permission: 'ACTE_REGLEMENTAIRE'
        },
        {
          title: 'Ordonnateurs',
          page: '/settings/ordonnateur/list',
          permission: 'ORDONNATEUR'
        },
        {
          title: 'Tiers',
          page: '/settings/tiers/list',
          permission: 'TIERS'
        },
        {
          title: 'Nomenclatures',
          page: '/settings/nomenclature/list',
          permission: 'NOMENCLATURE'
        },
        {
          title: 'Profils',
          page: '/settings/profil/list',
          permission: 'PROFIL'
        },
        {
          title: 'Utilisateurs',
          page: '/settings/users/list',
          permission: 'UTILISATEUR'
        },
        {
          title: 'Dotations',
          page: '/settings/dotation/list',
          permission: 'DOTATION'
        },
        {
          title: 'Services',
          page: '/settings/structure/list',
          permission: 'SERVICE'
        }        
      ]
    },
    {
      title: 'Commandes',
      root: true,
      bullet: 'dot',
      page: '/nomenclature-dashboard',
      icon: 'uil-box',
      svg: './assets/media/svg/icons/Design/Cap-2.svg',
      submenu: [
        {
          title: 'Type article',
          bullet: 'dot',
          page: '/nomenclature/budget',
          permission: 'TYPE_ARTICLE'         
        },
        {
          title: 'Article',
          bullet: 'dot',
          page: '/nomenclature/budget',
          permission: 'ARTICLE'          
        },      
        {
          title: 'Commandes',
          bullet: 'dot',
          page: '/nomenclature/budget',
          permission: 'COMMANDE'        
        }        
      ]
    },
    {
      title: 'Dépenses',
      root: true,
      bullet: 'dot',
      page: '/ngbootstrap',
      icon: 'uil-bag-alt',
      svg: './assets/media/svg/icons/Shopping/Bitcoin.svg',
      submenu: [
        {
          title: 'Engagement',
          page: '/ngbootstrap/accordion',
          permission: 'ENGAGEMENT'
        },
        {
          title: 'Engagement Salaire',
          page: '/ngbootstrap/alert',
          permission: 'ENGAGEMENT_SALAIRE'
        },
        {
          title: 'Mandat',
          page: '/ngbootstrap/buttons',
          permission: 'MANDAT_SAISIE'
        },
        {
          title: 'Nature dépense',
          page: '/ngbootstrap/carousel',
          permission: 'NATURE_DEPENSE'
        },
        {
          title: 'Dépense agent',
          page: '/ngbootstrap/collapse',
          permission: 'DEPENSE_AGENT'
        },
        {
          title: 'Validation ou Rejet engagement',
          page: '/ngbootstrap/datepicker',
          permission: 'VALIDATION_REJET_ENGAGEMENT'
        },
        {
          title: 'Bon engagement',
          page: '/ngbootstrap/dropdown',
          permission: 'BON_ENGAGEMENT'
        },
        {
          title: 'Boredereau de mandat',
          page: '/ngbootstrap/dropdown',
          permission: 'BORDEREAU_MANDAT'
        },
      ]
    },
    {
      title: 'Recette',
      bullet: 'dot',
      icon: 'uil-usd-square',
      svg: './assets/media/svg/icons/Shopping/Cart3.svg',
      root: true,
      permission: 'accessToECommerceModule',
      page: '/ecommerce',
      submenu: [
        {
          title: 'Ordre de recette',
          page: '/ecommerce/customers',
          permission: 'ENGAGEMENT_ORDRE_RECETTE'
        },
        {
          title: 'Réservation de crédit',
          page: '/ecommerce/products',
          permission: 'RESERVATION_CREDIT'
        },
        {
          title: 'Caisse avance',
          page: '/ecommerce/products',
          permission: 'CAISSE_AVANCE'
        },        
        {
          title: 'Régularisation',
          page: '/ecommerce/products',
          permission: 'REGULARISATION'
        },
      ]
    },
    {
      title: 'Facturation',
      root: true,
      bullet: 'dot',
      icon: 'uil-receipt',
      svg: './assets/media/svg/icons/General/User.svg',
      page: '/user-management',
      submenu: [
        {
          title: 'TVA',
          page: '/user-management/users',
          permission: 'TVA'
        },
        {
          title: 'Proforma',
          page: '/user-management/roles',
          permission: 'PROFORMA'
        },
        {
          title: 'Facture',
          page: '/user-management/roles',
          permission: 'FACTURE'
        },
        {
          title: 'Prise en charge',
          page: '/user-management/roles',
          permission: 'PRISEEN_CHARGE'
        },
        {
          title: 'Validation factures',
          page: '/user-management/roles',
          permission: 'VALIDATION_FACTURE'
        }

      ]
    },
    {
      title: 'Paie',
      root: true,
      bullet: 'dot',
      icon: 'uil-diary-alt',
      svg: './assets/media/svg/icons/Shopping/Box1.svg',
      page: '/wizards',
      submenu: [
        {
          title: 'Personnel',
          page: '/wizards/wizard-1',
          permission: 'PERSONNEL'
        },
        {
          title: 'Element de salaire',
          page: '/wizards/wizard-2',
          permission: 'ELEMENT_SALAIRE'
        },
        {
          title: 'Salaires',
          page: '/wizards/wizard-3',
          permission: 'SALAIRE'
        },
        {
          title: 'Etats',
          page: '/wizards/wizard-4',
          permission: 'ETATS'
        },
      ]
    },
    {
      title: 'Comptabilités',
      root: true,
      bullet: 'dot',
      icon: 'uil-puzzle-piece',
      svg: './assets/media/svg/icons/Code/Warning-2.svg',
      page: '/error',
      submenu: [
        {
          title: 'Paramètrage des jornaux',
          page: '/error/error-1',
          permission: 'PARAMETRAGE_JOURNAUX'
        },
        {
          title: 'Paramètrage des modes de réglement',
          page: '/error/error-2',
          permission: 'PARAMETRAGE_MODE_REGLEMENT'
        },
        {
          title: 'Paramètrage des types de retenues',
          page: '/error/error-3',
          permission: 'TYPES_DE_RETENUES'
        },
        {
          title: 'TVA précompté',
          page: '/error/error-4',
          permission: 'TVA_PRECOMPTE'
        },
        {
          title: 'Rapprochement bancaire',
          page: '/error/error-5',
          permission: 'RAPPROCHEMENT_BANCAIRE'
        },
        {
          title: 'Ecriture comptable',
          page: '/error/error-5',
          permission: 'ECRITURE_COMPTABLE'
        },
        {
          title: 'Balance',
          page: '/error/error-5',
          permission: 'BALANCE'
        },
      ]
    },
    {
      title: 'SICA',
      root: true,
      bullet: 'dot',
      icon: 'uil-briefcase',
      svg: './assets/media/svg/icons/Shopping/Box1.svg',
      page: '/wizards',
      submenu: [
        {
          title: 'Paramètrage AC et compte de dépôt',
          page: '/wizards/wizard-1',
          permission: 'PARAMETRAGE_AC_COMPTE_DEPOT'
        },
        {
          title: 'Compte forunisseur',
          page: '/wizards/wizard-2',
          permission: 'COMPTE_FOURNISSEUR'
        },
        {
          title: 'Compte personnel',
          page: '/wizards/wizard-3',
          permission: 'COMPTE_PERSONNEL'
        },
        {
          title: 'Virement fournisseur',
          page: '/wizards/wizard-4',
          permission: 'VIREMENT_FOURNISSEUR'
        },
        {
          title: 'Virement salaire',
          page: '/wizards/wizard-4',
          permission: 'VIREMENT_SALAIRE'
        },
      ]
    },
    {
      title: 'Editions',
      root: true,
      bullet: 'dot',
      icon: 'uil-presentation',
      svg: './assets/media/svg/icons/Code/Warning-2.svg',
      page: '/error',
      submenu: [
        {
          title: 'Prévision',
          page: '/error/error-1',
          permission: 'PREVISION'
        },
        {
          title: 'Crédit engagé',
          page: '/error/error-2',
          permission: 'CREDIT_ENGAGE'
        },
        {
          title: 'Crédit ordonnance',
          page: '/error/error-3',
          permission: 'CREDIT_ORDONNANCE'
        },
        {
          title: 'Recette',
          page: '/error/error-4',
          permission: 'RECETTES'
        },
        {
          title: 'Situation budgétaire',
          page: '/error/error-5',
          permission: 'SITUATION_EXERCICE_BUDGETAIRE'
        },
      ]
    },
    {
      title: 'Notes',
      root: true,
      bullet: 'dot',
      icon: 'uil-notes',
      svg: './assets/media/svg/icons/Code/Warning-2.svg',
      page: '/error',
      submenu: [
        {
          title: 'Note 1 : Dettes',
          page: '/error/error-1',
          permission: 'NOTE_1'
        },
        {
          title: 'Note 2 : Informations obligatoires',
          page: '/error/error-1',
          permission: 'NOTE_2'
        },
        {
          title: 'Note 3 : Immobilisations',
          page: '/error/error-1',
          permission: 'NOTE_3'
        },
        {
          title: 'Note 4 : Immobilisations financières',
          page: '/error/error-1',
          permission: 'NOTE_4'
        },
        {
          title: 'Note 5 : Créances et dettes HAO',
          page: '/error/error-1',
          permission: 'NOTE_4'
        },
        {
          title: 'Note 6 : Stock et encours',
          page: '/error/error-1',
          permission: 'NOTE_6'
        },
        {
          title: 'Note 7 : Client',
          page: '/error/error-1',
          permission: 'NOTE_7'
          
        },
      ]
    },
  ]

};