import { Component, OnInit } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

import { StorageService } from '../../service/storage.service';
import { OfferSrevice } from '../../service/offer.service';

@Component({
  selector: 'app-offerhome',
  templateUrl: './offerhome.component.html',
  styleUrls: ['./offerhome.component.scss']
})
export class OfferhomeComponent implements OnInit {

  /**
   * Offers user
   */
  offers: any;
  
  constructor(private storageService: StorageService, 
    private offerService: OfferSrevice) { 
  }

  ngOnInit() {
    let accountId = this.storageService.getAccountId();
    this.offerService.getOffers(accountId).subscribe(offers => {
      this.offers = offers;
      console.log('Offers ', this.offers);
    });
  }

  getMainImage(offer:any) : Promise<SafeUrl> {
    return null;
  }

}
