import { Component, OnInit } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Router } from '@angular/router';
import { DemandesService } from '../../service/demandes.service';
import { map } from 'rxjs/operators';
import { HomeService } from '../../service/home.service';
import { AuthService } from '../../service/auth.service';
import { UserIdleService } from 'angular-user-idle';
import { TranslateService } from '@ngx-translate/core';
import { EncodedJwtToken, AccountType } from "../../model/AuthResource";
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  /**
   * Available languages
   */
  langs: any;
  currentLang: string;
  encodedJwtToken : EncodedJwtToken;
  searchDemandeForm: FormGroup;
  demande:any;
  connected: boolean = false;

  constructor(private formBuilder: FormBuilder, private router: Router, 
    private homeService: HomeService, private demandesService: DemandesService, 
    private authService: AuthService,
    private userIdle: UserIdleService,
    private translateService: TranslateService) {  
      this.langs = translateService.getLangs();
  }

  ngOnInit() { 
    this.currentLang = this.translateService.currentLang;
    this.encodedJwtToken = this.authService.getEncodedJwtToken();
    this.connected = this.encodedJwtToken !== null;
    this.searchDemandeForm = this.formBuilder.group({
      identifiantDemande: ['', [Validators.required]],
    });
  }

  disconnect() {   
    this.authService.logout();
    this.userIdle.stopWatching();
    this.router.navigate(['/dashboard/home']);
    //window.location.reload();
  }

  isLoggedIn() {
    return this.authService.isLogged();
  }

  isProfile() {
    return this.router.url.startsWith('/p');
  }

  canSeeProfile() {
    //return true;
    /*return this.homeService.getCurrentHome().pipe(map(ret => {
        const home = new Resources(ret);
        return home.getRel('managers');
    }));
    */
  }

  useLanguage(selectedLanguage) {
    this.translateService.use(selectedLanguage);
    this.currentLang = this.translateService.currentLang;
  }

  submitForm(){
    this.demandesService.getDemandeByIdentifiant(this.searchDemandeForm.value.identifiantDemande).subscribe(demande => {
      this.demande = demande;
      console.log('demande => ', this.demande);
    });
  }

}
