import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { mergeMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { StorageService } from '../service/storage.service';
import { DemoDataService } from '../service/data.service';

import { environment } from '../../environments/environment';
import { AuthResource } from '../model/AuthResource';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { OfferForm} from '../model/rest/OfferForm';
import { Category } from '../model/rest/Category';



/**
 * 
 */
@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private httpClient: HttpClient, 
    private router: Router, 
    private storageService: StorageService,
    private demoDataService: DemoDataService) {
  }

  /*P
  environment.baseURLAPI + home.getRel('auth')
  */
  getAllCategoriesReference(): Observable<Array<Category>> {
    return this.httpClient.get<Array<Category>>('http://localhost:8083/api/offer/category');
  }

}
