import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { NgModel, NgForm } from "@angular/forms";

import { HomeService } from '../../service/home.service';
import { StorageService } from '../../service/storage.service';
import { AuthService } from '../../service/auth.service';
import { DemoDataService } from '../../service/data.service'
import { IdleService } from '../../service/idle.service'

@Component({
  selector: 'account-activation',
  templateUrl: './account-activation.component.html',
  styleUrls: ['./account-activation.component.scss']
})
export class AccountActivationComponent implements OnInit {

  pageErrorMessage : { display: boolean, message : string} = { display: false, message : '' };

  emailValue: string ;
  userValue: string = '';
  passwordValue: string;
  emailDemoValue: string;
  clientNameValue: string;
  returnUrl: string;
  errMessage: string;
  otherError = false;
  credentialsError = false;
  loggedIn = false;
  hasResetRel = true;
  authenticationInProgress = false;

  accountActivated = false;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private homeService: HomeService,
    private authService: AuthService,
    private storageService: StorageService,
    private demoDataService: DemoDataService,
    private idleService: IdleService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      let email = params['email'];
      this.doValidateAccount(email);
    });
  }

  doValidateAccount(email: string) {

    this.authenticationInProgress = true;

    this.authService.logout();
    this.otherError = false;
    this.credentialsError = false;

    this.authService.validateAccount(email).subscribe(resource => {
      console.info('AuthResource ', resource);
      this.loggedIn = true;
      this.authenticationInProgress = false;
      this.idleService.startWatch();

      this.accountActivated = true;
    },
    err => {
      if(err.status === 400) {
        this.pageErrorMessage = { display: true, message : err.error.message};
      } 
      else if (err.status === 405) {
        this.pageErrorMessage = { display: true, message : err.error.message};
        this.accountActivated = true;
      } 
      else {
        this.pageErrorMessage = { display: true, message : err.message};
        this.otherError = true;
        this.errMessage = err.message;
      }
      console.info('Error ', err);
      this.authenticationInProgress = false;
    });
  }

  doLogin() {
    this.router.navigate(['/auth']);
  }

  clearError() {
    this.pageErrorMessage = { display: false, message : ''};
  }

}
