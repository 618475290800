export class AccountForm {

    firstname : string;
	lastname : string;
	email : string;
	password: string;
	birthdate : string;
	phoneNumber: string;
	accountType :string;
}

export class AccountDealerForm extends AccountForm {
	accountType :string ="DEALER";
	organisationName : string;
	organisationLocation : string;
	organisationCreationDate : string;
}

export class AccountSellerForm extends AccountForm {
	accountType :string ="SELLER";
}