import { Injectable } from '@angular/core';
import { UserIdleService } from 'angular-user-idle';
import { Router } from '@angular/router';

import { AuthService } from '../service/auth.service';
import { StateService } from '../service/state.service';
import { StorageService } from '../service/storage.service';

@Injectable({
  providedIn: 'root'
})
export class IdleService {

  pingValue: number = 5;
  timeOutValue: number = 5;
  
  constructor(private router: Router, 
    private userIdle: UserIdleService,
    private stateService: StateService,
    private storageService: StorageService,
    private authService: AuthService){ this.startWatch(); }

  startWatch() {
    let token = this.storageService.getToken();
    if(token) {
      this.watch();
    }
  }

  private watch() {
    this.userIdle.stopWatching();

    this.userIdle.setConfigValues({idle:1800, timeout:this.timeOutValue, ping:this.pingValue});

    //Start watching for user inactivity.
    this.userIdle.startWatching();

    this.userIdle.onTimerStart().subscribe(() => console.log('Déconnexion imminente...'));

    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      console.log("Déconnecté pour inactivité");
      this.logout();
    });

    this.userIdle.ping$.subscribe(() => {
      let expiresTime = this.storageService.getExpiresTime();
      if(expiresTime) {
        var updatedExpiresTime = Number(expiresTime) - this.pingValue;
        this.storageService.updateExpiresIn(Number(updatedExpiresTime));
        if(updatedExpiresTime < this.pingValue) {
          console.log("expiration du token, veuillez vous reconnecter!");
          this.logout();
        }
      }
    });
  }

  private logout() {
    this.authService.logout();
    this.userIdle.stopWatching();
    this.router.navigate(['/auth']);
  }
}
