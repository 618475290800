import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { LayoutComponent } from './layout/layout.component';
import { LayoutPrivateComponent } from './layout/layout-private.component';
import { LayoutWrapperComponent } from './layout/layout-wrapper.component';
import { LayoutProComponent } from './layout/layout-pro.component';

import { HeaderComponent } from './header/header.component';
import { HeaderProComponent } from './header/header-pro.component';

import { FooterComponent } from './footer/footer.component';
import { MenuComponent } from './menu/menu.component';
import { UdDropdownComponent } from "./components/dropdown/dropdown.component";
import { UdDropdownItemComponent } from "./components/dropdown/dropdown-item.component";

import { WizardStepsComponent } from "./components/wizard-steps/wizard-steps.component";


import { UdIconComponent } from "./components/ud-icon/ud-icon.component";
import { ASideComponent } from "./aside/aside.component";
import { TopbarComponent } from "./topbar/topbar.component";
import { RightBarComponent } from './rightbar/rightbar.component';
import { HttpLoadingComponent } from "./http-loading/http-loading.component";

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        })
    ],
    declarations: [
        LayoutComponent, LayoutPrivateComponent, LayoutWrapperComponent, LayoutProComponent,
        HeaderComponent, HeaderProComponent, FooterComponent, MenuComponent, RightBarComponent,
        UdDropdownComponent, UdDropdownItemComponent, WizardStepsComponent,
        UdIconComponent, ASideComponent, TopbarComponent, HttpLoadingComponent
    ],
    exports: [
        LayoutComponent, 
        LayoutPrivateComponent, 
        LayoutWrapperComponent, 
        LayoutProComponent,
        UdIconComponent, 
        WizardStepsComponent,
        FooterComponent, 
        RightBarComponent,
        ASideComponent, 
        TopbarComponent,
        HttpLoadingComponent
    ]
})
export class UiModule { }
