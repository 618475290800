export class CreateUserResource {
  title: string;
  last_name: string;
  first_name: string;
  partner_user_id: string;
  
  constructor(title: string, firstName: string, lastName: string, partnerUserId: string) {
    this.title = title;
    this.last_name = lastName;
    this.first_name = firstName;
    this.partner_user_id = partnerUserId;
  }
}