import { Component, OnInit } from '@angular/core';
import { find, filter, pick, cloneDeep } from "lodash";

import { OfferSrevice } from "../../service/offer.service";
import { OfferForm, OfferStatus } from '../../model/rest/OfferForm';

@Component({
  selector: 'app-accountdashbord',
  templateUrl: './accountdashbord.component.html',
  styleUrls: ['./accountdashbord.component.scss']
})
export class AccountdashbordComponent implements OnInit {

  originalOffers: Array<any>;
  offers: Array<any>;

  public form : {
    text: string,
    price: null,
    category: "",
    visibility: "ALL" | "PUBLIC" | "PRIVATE",
    offerstatus: OfferStatus.DISABLED
  };

  constructor(private offerService: OfferSrevice) {
    this.form = {
      text: "",
      price: null,
      category: "",    
      visibility: "ALL",
      offerstatus: OfferStatus.DISABLED
    };
  }

  ngOnInit() {
    // Get all offers
    this.offerService.getOffersForCurrentUser().then((offers) => {
      console.log(offers);
      this.originalOffers = offers;
      this.filterByCriteria();
    });
  }


  /**
   * get main image (the first image in the array)
   * 5e2ffdc50584fb89aa2a1fc5
   */
  getMainImage(offer) {
    return this.offerService.getMainImage(offer);
  }

  filterByCriteria() {
    console.info(this.form);
    this.offers = filter(this.originalOffers, (o) => {
      if (this.form.visibility == 'PUBLIC' || this.form.visibility == 'PRIVATE') {
        return o.visibility == this.form.visibility;
      }
      return true;
    });
  }

}
