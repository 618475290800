/**
 * Représente les paramètres d'entrée du service permettant aux partenaires de valider ou refuser des démarches utilisateurs.
 */
export interface QualifyProcedureInput {
    
    /** 
     * Nouveau statut à définir pour la démarche 
     */
    status: string;

    /**
     * Cause du refus de la démarche
     */
    refusal_ground: string;
  
    /**
     * Liste de documents refusés
     */
    refused_documents: RefusedDocument[];
    
}
  
export enum ProcedureStatus {
    /**
     * Démarrée
     */
    STARTED, 
    /**
     * En attente de validation (NE concerne PAS {@link ProcedureType#PERSONAL})
     */
    REQUESTED, 
    /**
     * Validée (NE concerne PAS {@link ProcedureType#PERSONAL})
     */
    VALIDATED, 
    /**
     * Complétée par l'UTILISATEUR (Concerne uniquement {@link ProcedureType#PERSONAL})
     */
    COMPLETED, 
    /**
     * Obsolète
     */
    OBSOLETED
}

/**
 * Représente les informations concernant un document d'une démarche qui a été refusé par le partenaire en charge de la validation de la
 * démarche.
 */
export interface RefusedDocument {
    document_id: string;  
    refusal_ground: string;
}

export interface QualificationProcedure {
    userProcedureId: string;
    refused_documents: RefusedDocument[];
    validatedDocumentsId: string[];
}