import { Injectable } from '@angular/core';

import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { HomeService } from './home.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  menu: any[];
  filteredMenu: Observable<any[]>;

  constructor(private router: Router, private homeService: HomeService) {
    this.menu = this.router.config
      .filter(route => route.data && route.data.inMenu)
      .map(route => {
          return {
              path: route.path,
              title: route.data.title,
              icon: route.data.icon,
              needsRel: route.data.needsRel,
              children: route.children ? route.children
                .filter(childRoute => childRoute.data && childRoute.data.inMenu)
                .map(childRoute => {
                    return {
                        path: route.path + '/' + childRoute.path,
                        title: childRoute.data.title,
                        needsRel: childRoute.data.needsRel
                    };
                }) : false
          };
      });
  }

  _testRels(route, home, gestionnaire): boolean {
    if(route.needsRel) {
      if(route.needsRel.home) {
        if(home && !route.needsRel.home.reduce((prev, next) => prev && home.getRel(next), true)) {
          return false;
        }
      }
      if(route.needsRel.gestionnaire) {
        if(gestionnaire && !route.needsRel.gestionnaire.reduce((prev, next) => prev && gestionnaire.getRel(next), true)) {
          return false;
        }
      }
    }
    return true;
  }

  getMenu(): Observable<any[]> {
    if(!this.filteredMenu) {
      this.filteredMenu = this.filterMenu();
    }
    return this.filteredMenu;
  }

  filterMenu(): Observable<any[]> {
    return null;
  }
}
