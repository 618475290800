import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { mergeMap, map } from 'rxjs/operators';
import { DemandeStatus} from '../model/rest/DemandeStatus';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';
import { DemandesData} from '../model/rest/DemandesData';
import { DemarcheType} from '../model/rest/DemarcheType';
import { ProcessDemandeData} from '../model/rest/ProcessDemandeData';
import { TraitementData} from '../model/rest/TraitementData';
import { environment } from '../../environments/environment';

/**
 * 
 */
@Injectable({
  providedIn: 'root'
})
export class DemandesService {

  private urlApi:string = environment.baseURLAPI +'api/demandes';

  constructor(private httpClient: HttpClient, 
    private router: Router, 
    private storageService: StorageService,
    private _sanitizer: DomSanitizer) {
  }

  getDemandeTranscriptionByStatus(status:any): Observable<any> {
    const url = this.urlApi + `/transcription/status/` + status;
    return this.httpClient.get(url);
  }

  getDemandeActesByStatus(status:any): Observable<any> {
    const url = this.urlApi + `/acte/status/` + status;
    return this.httpClient.get(url);
  }

  getDemandeActes(organisationId:any): Observable<any> {
    const url = this.urlApi + `/mairie/actes/` + organisationId;
    return this.httpClient.get(url);
  }

  getDemandeActesByUser(): Observable<any> {
    const url = this.urlApi + `/actes`;
    return this.httpClient.get(url);
  }

  getDemandeTranscriptionByUser(): Observable<any> {
    const url = this.urlApi + `/transcriptions`;
    return this.httpClient.get(url);
  }

  getDemandeTranscriptionById(id:number): Observable<any> {
    const url = this.urlApi + `/transcription`;
    return this.httpClient.get(url + '/' + id);
  }  

  getDemandeActeById(id:number): Observable<any> {
    const url = this.urlApi + `/acte`;
    return this.httpClient.get(url + '/' + id);
  }  

  getDemandeTranscription(organisationId:any): Observable<any> {
    const url = this.urlApi + `/mairie/transcription/`  + organisationId;
    return this.httpClient.get(url);
  }

  getDemandeByIdentifiant(identifiantDemande:any): Observable<any> {
    const url = this.urlApi + `/`;
    return this.httpClient.get(url + identifiantDemande);
  }

  getDemandes(): Observable<any> {
    const url = `${this.urlApi}`;
    return this.httpClient.get(url);
  }

  getMessages(organisationId:any): Observable<any> {
    const url = this.urlApi + `/message/` + organisationId;
    return this.httpClient.get(url);
  }

  traiterDemande(traitementData:TraitementData): Promise<DemandesData>{
    const url = this.urlApi + `/traitement`;
    var formData = new FormData();
    formData.append( "demandeType", traitementData.demandeType );
    formData.append( "id", traitementData.id );
    ( traitementData.fichier1 ) && formData.append( "fichier1", traitementData.fichier1 );
    ( traitementData.fichier2 ) && formData.append( "fichier2", traitementData.fichier2 );    
    ( traitementData.fichier3 ) && formData.append( "fichier3", traitementData.fichier3 );    

    return this.httpClient.post(url, formData)
      .pipe(
        map((res: any) => {
          return res;
        })
    ).toPromise();
  }

  createDemandesTranscription(demandesData:DemandesData): Promise<DemandesData>{
	  const url = this.urlApi + `/transcription`;
    console.info("demandesData =>" + JSON.stringify(demandesData));

    var formData = new FormData();
       
    formData.append( "civilite", demandesData.civilite );
    formData.append( "nomDemandeur", demandesData.nomDemandeur );
    formData.append( "nomUsage", demandesData.nomUsage );
    formData.append( "prenomDemandeur", demandesData.prenomDemandeur );
    formData.append( "email", demandesData.email );
    formData.append( "adressePostale", demandesData.adressePostale );
    formData.append( "telephone", demandesData.telephone );
    formData.append( "lienParente", demandesData.lienParente );
    formData.append( "civiliteInteresse", demandesData.civiliteInteresse );
    formData.append( "nomInteresse", demandesData.nomInteresse );
    formData.append( "prenomInteresse", demandesData.prenomInteresse );
    formData.append( "dateNaissanceInteresse", demandesData.dateNaissanceInteresse );
    formData.append( "nomPere", demandesData.nomPere );
    formData.append( "prenomPere", demandesData.prenomPere );
    formData.append( "nomMere", demandesData.nomMere );
    formData.append( "prenomMere", demandesData.prenomMere );
    formData.append( "departement", demandesData.departement );
    formData.append( "commune", demandesData.commune );
    formData.append( "mairie", demandesData.mairie );
    formData.append( "typeDeDocumentDemandeur", demandesData.typeDeDocumentDemandeur );
    formData.append( "typeDeDocumentInteresse", demandesData.typeDeDocumentInteresse );
    formData.append( "commentaires", demandesData.commentaires );
    formData.append( "nbreDeCopie", demandesData.nombreCopie );
    formData.append( "modeDeNotification", demandesData.modeDeNotification );
    formData.append( "modeDeReception", demandesData.modeDeReception );

    ( demandesData.documentFileInteresse ) && formData.append( "documentInteresse", demandesData.documentFileInteresse );
    ( demandesData.documentFileDemandeur ) && formData.append( "documentDemandeur", demandesData.documentFileDemandeur );

    return this.httpClient.post(url, formData)
      .pipe(
        map((res: any) => {
          return res;
        })
      ).toPromise();
  }  

  createDemandesActeSimplifie(demandesData:DemandesData): Promise<DemandesData>{
	  const url = `${this.urlApi}`;
    console.info("demandesData =>" + JSON.stringify(demandesData));

    var formData = new FormData();
       
    formData.append( "civilite", demandesData.civilite );
    formData.append( "nomDemandeur", demandesData.nomDemandeur );
    formData.append( "nomUsage", demandesData.nomUsage );
    formData.append( "prenomDemandeur", demandesData.prenomDemandeur );
    formData.append( "email", demandesData.email );
    formData.append( "adressePostale", demandesData.adressePostale );
    formData.append( "telephone", demandesData.telephone );
    formData.append( "lienParente", demandesData.lienParente );
    formData.append( "numeroCNI", demandesData.numeroCNI );
    formData.append( "numeroRegistre", demandesData.numeroRegistre );
    formData.append( "commentaires", demandesData.commentaires );
    formData.append( "nbreDeCopie", demandesData.nombreCopie );
    formData.append( "modeDeNotification", demandesData.modeDeNotification );
    formData.append( "modeDeReception", demandesData.modeDeReception );

    return this.httpClient.post(url, formData)
      .pipe(
        map((res: any) => {
          return res;
        })
      ).toPromise();
  }

  createDemandes(demandesData:DemandesData): Promise<DemandesData>{
	  const url = `${this.urlApi}` + '/acte';
    console.info("demandesData =>" + JSON.stringify(demandesData));

    var formData = new FormData();
       
    formData.append( "civilite", demandesData.civilite );
    formData.append( "nomDemandeur", demandesData.nomDemandeur );
    formData.append( "nomUsage", demandesData.nomUsage );
    formData.append( "prenomDemandeur", demandesData.prenomDemandeur );
    formData.append( "email", demandesData.email );
    formData.append( "adressePostale", demandesData.adressePostale );
    formData.append( "telephone", demandesData.telephone );
    formData.append( "lienParente", demandesData.lienParente );
    formData.append( "civiliteInteresse", demandesData.civiliteInteresse );
    formData.append( "nomInteresse", demandesData.nomInteresse );
    formData.append( "prenomInteresse", demandesData.prenomInteresse );
    formData.append( "dateNaissanceInteresse", demandesData.dateNaissanceInteresse );
    formData.append( "nomPere", demandesData.nomPere );
    formData.append( "prenomPere", demandesData.prenomPere );
    formData.append( "nomMere", demandesData.nomMere );
    formData.append( "prenomMere", demandesData.prenomMere );
    formData.append( "departement", demandesData.departement );
    formData.append( "commune", demandesData.commune );
    formData.append( "mairie", demandesData.mairie );
    formData.append( "typeDeDocumentDemandeur", demandesData.typeDeDocumentDemandeur );
    formData.append( "typeDeDocumentInteresse", demandesData.typeDeDocumentInteresse );
    formData.append( "commentaires", demandesData.commentaires );
    formData.append( "nbreDeCopie", demandesData.nombreCopie );
    formData.append( "modeDeNotification", demandesData.modeDeNotification );
    formData.append( "modeDeReception", demandesData.modeDeReception );

    ( demandesData.documentFileInteresse ) && formData.append( "documentInteresse", demandesData.documentFileInteresse );
    ( demandesData.documentFileDemandeur ) && formData.append( "documentDemandeur", demandesData.documentFileDemandeur );

    return this.httpClient.post(url, formData)
      .pipe(
        map((res: any) => {
          return res;
        })
      ).toPromise();
  }


  processDemande(processDemande:ProcessDemandeData): Promise<DemandesData>{
    const url = this.urlApi + `/process/acte`;
    console.info("processDemande =>" + JSON.stringify(processDemande));
    var formData = new FormData();
    formData.append( "demandeId", "" + processDemande.demandeId );
    formData.append( "message", processDemande.message );
    formData.append( "demandeStatus", processDemande.demandeStatus );
    return this.httpClient.post(url, formData)
    .pipe(
      map((res: any) => {
        return res;
      })
    ).toPromise();
  }

  processDemandeTranscription(processDemande:ProcessDemandeData): Promise<DemandesData>{
    const url = this.urlApi + `/process/transcription`;
    console.info("processDemande =>" + JSON.stringify(processDemande));
    var formData = new FormData();
    formData.append( "demandeId", "" + processDemande.demandeId );
    formData.append( "message", processDemande.message );
    formData.append( "demandeStatus", processDemande.demandeStatus );
    return this.httpClient.post(url, formData)
    .pipe(
      map((res: any) => {
        return res;
      })
    ).toPromise();
  }

  validerDemande(demandeId:any, codeDeValidation:string, demandeType:string): Promise<DemandesData>{
    const url = this.urlApi + `/validation`;
    var formData = new FormData();
    formData.append( "demandeId", demandeId );
    formData.append( "codeDeValidation", codeDeValidation );
    formData.append( "demandeType", demandeType );
    return this.httpClient.post(url, formData)
    .pipe(
      map((res: any) => {
        return res;
      })
    ).toPromise();
  }  

  sendMessageDemande(demandeId:any, message:string, demarcheType:DemarcheType): Promise<string>{
    const url = this.urlApi + `/message`;
    var formData = new FormData();
    formData.append( "demarcheId", demandeId );
    formData.append( "text", message );
    formData.append( "demarcheType", demarcheType );
    return this.httpClient.post(url, formData)
    .pipe(
      map((res: any) => {
        return res;
      })
    ).toPromise();
  } 

}


