import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { mergeMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';
import { OrganisationData} from '../model/rest/OrganisationData';

/**
 * 
 */
@Injectable({
  providedIn: 'root'
})
export class OrganisationService {

  private urlApi:string = 'http://localhost:8081/api/account/organisation';

  constructor(private httpClient: HttpClient, 
    private router: Router, 
    private storageService: StorageService,
    private _sanitizer: DomSanitizer) {
  }

  getOrganisation(): Observable<any> {
    const url = `${this.urlApi}`;
    return this.httpClient.get(url);
  }
  
  getOrganisationById(organisationId:any): Observable<any> {
    const url = `${this.urlApi}/view/`;
    return this.httpClient.get(url + organisationId);
  }

  createOrganisation(organisationData:OrganisationData): Promise<string>{
	  const url = `${this.urlApi}`;
    console.info("organisationData 3 => " + JSON.stringify(organisationData));
    var formData = new FormData();

    formData.append( "nom", organisationData.nom );
    formData.append( "description", organisationData.description );
    formData.append( "responsable", organisationData.responsable );
    formData.append( "siegeSocial", organisationData.siegeSocial);
    formData.append( "telephone", organisationData.telephone );
    formData.append( "codeColeur1", organisationData.codeColeur1 );
    formData.append( "codeColeur2", organisationData.codeColeur2 );
    formData.append( "codeColeur3", organisationData.codeColeur3 );
    ( organisationData.logo ) && formData.append( "logo", organisationData.logo );
    console.info("formData => " + JSON.stringify(formData));
    return this.httpClient.post(url, formData)
      .pipe(
        map((res: any) => {
          return res;
        })
      ).toPromise();
  }
}
