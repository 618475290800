import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../service/auth.service';
import { EncodedJwtToken } from "../../model/AuthResource";
import { OrganisationData} from '../../model/rest/OrganisationData';
import { OrganisationService } from '../../service/organisation.service';

@Component({
  selector: 'app-layout-wrapper',
  templateUrl: './layout-wrapper.component.html',
  styleUrls: ['./layout-wrapper.component.scss']
})
export class LayoutWrapperComponent implements OnInit {

  encodedJwtToken : EncodedJwtToken;
  organisationLogo:any;

  constructor(public translate: TranslateService,    private organisationService: OrganisationService, private authService: AuthService) {
  }

  ngOnInit() {
    // this.encodedJwtToken = this.authService.getEncodedJwtToken(); 
    // this.recupererLogoOrganisation(this.encodedJwtToken.organisationId);
  }
  
  recupererLogoOrganisation(organisationId:any){
    this.organisationService.getOrganisationById(organisationId).subscribe(organisationLogo => {
      this.organisationLogo = organisationLogo.logo;
      console.log('organisationLogo => ', this.organisationLogo);
    });    
  }

  getOrganisationLogo(): any {
    if( this.organisationLogo == null){
      return "assets/images/logo-sigbuc.png";
    }
    return 'data:image/jpeg;base64,' +  this.organisationLogo;
  }

}