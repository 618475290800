import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, throwError, of } from 'rxjs';
import { mergeMap, map, tap, catchError, shareReplay } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  currentHome: Observable<any>;

  constructor(private http: HttpClient) {}

  handleError(operation: string) {
    return (err: any) => {     
      return throwError(err);
    };
  }

  getHome(): Observable<any> {
    this.currentHome = this.http.get<any>(environment.baseURLAPI + '/home').pipe(tap(home => {      
    }), catchError(this.handleError('getHome')), shareReplay(1));
    return this.currentHome;
  }

  getCurrentHome(): Observable<any> {
    if(!this.currentHome) {
      this.getHome();
    }
    return this.currentHome;
  }

  resetHome() {
    this.currentHome = null;
  }

}
