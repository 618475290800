import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StorageService } from '../service/storage.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private storageService: StorageService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const token = this.storageService.getToken();
    if (token) {
      console.info('Access the app...');
      return true;
    }
    this.router.navigate(['/auth']);
    return false;
  }
}
