import { Injectable } from '@angular/core';
import { mergeMap, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

import { StorageService } from './storage.service';
import { environment } from '../../environments/environment';
import { ProceduresResource } from '../model/rest/ProceduresResource';
import { CreateUserResource } from '../model/rest/CreateUserResource';
import { UserProcedureData } from '../model/rest/UserProcedureData';
import { QualifyProcedureInput } from '../model/rest/Qualification';

import { Observable } from '../../../node_modules/rxjs';

/**
 * 
 */
@Injectable({
  providedIn: 'root'
})
export class DemarchesService {

  constructor(private httpClient: HttpClient,
       private router: Router,
       private storageService: StorageService) { }

  /**
   * Recupere la liste des démarches pour un univers (demarche PID)
   * @param procedurePid 
   */
  getListDemarches(procedurePid: string): Observable<ProceduresResource> {

    return this.httpClient.get(environment.baseURLAPI + environment.apiVersion +'partner/procedures/' + procedurePid)
      .pipe(
        map((res: ProceduresResource) => {
            return res;
        })
      );
  }

  /**
   * Créé une démarche utilisateur
   * 
   * @param civility 
   * @param firstName 
   * @param lastName 
   * @param partnerUserId 
   */
  createUser(civility: string, firstName: string, lastName: string, partnerUserId: string) {

    let createUser = new CreateUserResource(civility, firstName, lastName, partnerUserId);
	  let headers = new HttpHeaders().append('Content-Type', 'application/json');

    let httpOptions = {
      headers: headers
    };

    return this.httpClient.post(environment.baseURLAPI + environment.apiVersion +'user/partial', createUser, httpOptions)
      .pipe(
        map((res: any) => {
            return res;
        })
      );
  }
  

  /**
   * Recupère un nouveau lien de personnalisation de compt
   * @param partnerUserId 
   */
  createCustomizationUrl(partnerUserId: string) {
    let headers = new HttpHeaders().append('Content-Type', 'application/json').append('X-PartnerUserId', partnerUserId);

    let httpOptions = {
      headers: headers
    };

    return this.httpClient.post(environment.baseURLAPI + environment.apiVersion +'user/customizationurl', null, httpOptions)
      .pipe(
        map((res: any) => {
            return res;
        })
      );
  }

  /**
   * Recupere les statuts des documents d'une démarche utilisateur
   * @param procedureUserId the user procedure id
   */
  getDocumentsProcedureStatus(procedureUserId: string, partnerUserId: string): Observable<UserProcedureData> {
    let httpOptions = {
      headers: new HttpHeaders().append('Content-Type', 'application/json').append('X-PartnerUserId', partnerUserId)
    };
    return this.httpClient.get(environment.baseURLAPI + environment.apiVersion +'procedure/user/' + procedureUserId, httpOptions)
      .pipe(
        map((res: UserProcedureData) => {
          return res;
       })
    );
  }

 /**
   * Recupere les statuts des documents d'une démarche utilisateur
   * @param procedureUserId the user procedure id
   */
  getDocumentMetadata(procedureUserId: string, documentId: string): Observable<any> {
    const urlDocumentMetadata = `partner/procedure/${procedureUserId}/document/${documentId}/metadata`;
    return this.httpClient.get(environment.baseURLAPI + environment.apiVersion + urlDocumentMetadata)
      .pipe(
        map((res: any) => {
          console.info('Metadata ', res);
          return res;
       })
    );
  }

  
  qualifyProcedure(procedureUserId: string, qualification: QualifyProcedureInput): Observable<boolean>  {
    const urlQualifyProcedure = `partner/procedure/${procedureUserId}/qualify`;
    return this.httpClient.put(environment.baseURLAPI + environment.apiVersion + urlQualifyProcedure, qualification)
      .pipe(
        map((res: any) => {
          console.info('Qualification ', res);
          return true;
       })
    );
  }


}