import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { mergeMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { StorageService } from '../service/storage.service';
import { DemoDataService } from '../service/data.service';

import { environment } from '../../environments/environment';
import { AuthResource } from '../model/AuthResource';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { AccountForm} from '../model/rest/AccountForm';


/**
 * 
 */
@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(private httpClient: HttpClient, 
    private router: Router, 
    private storageService: StorageService,
    private demoDataService: DemoDataService) {
  }

  /*
  environment.baseURLAPI + home.getRel('auth')
  */
  createAccount(accountForm:AccountForm): Observable<string> {
    return this.httpClient.post('http://localhost:8081/api/account/credentials', accountForm)
      .pipe(
        map((res: any) => {
          return res;          
        })        
      );
  }

  isLogged(): boolean {
    return !!this.storageService.getToken();
  }

  logout() {
    this.storageService.clearToken();
    // this.demoDataService.logout();
  }

}
