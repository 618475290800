import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { UiModule } from './ui/ui.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { environment } from '../environments/environment';
import { UserIdleModule } from 'angular-user-idle';

import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { HomeComponent } from './home/home.component';
import { SearchComponent } from './search/search.component';

import { DemarchesComponent, StatsDemarchesComponent, CreateDemarcheComponent, ListDemarchesComponent, DetailsDemarcheComponent, MimetypePipe } from './demarches/demarches.component';
import { TokenHeaderInterceptorProviders } from './interceptors/token.header.interceptor';
import { HttpLoadingInterceptorProviders } from './interceptors/http-loading.interceptor';

import { AuthGuard } from './auth/auth.guard';
import { AccountPartGuard, AccountProGuard, PublicGuard } from './commons/guards-module/guards.service';

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { RegisterComponent } from './register/register.component';
import { RegistersellerComponent } from './registerseller/registerseller.component';
import { RegisterdealerComponent } from './registerdealer/registerdealer.component';
import { RegisterHomeComponent } from './register/home/registerhome.component';
import { CommonRegisterComponent } from './register/common-register/common-register.component';
import { AccountdashbordComponent } from './account/accountdashbord/accountdashbord.component';
import { importExpr } from '@angular/compiler/src/output/output_ast';

import { OfferComponent } from './offer/offer.component';
import { CommonOfferComponent } from './offer/common-offer/common-offer.component';
import { OfferhomeComponent } from './offer/home/offerhome.component';
import { CreateofferComponent } from './offer/createoffer/createoffer.component';
import { SellersSaysComponent } from './commons/sellers-says/sellers-says.component';
import { AccountActivationComponent } from './account/account-activation/account-activation.component';
import { SecuredImageComponent } from './commons/secured-image/secured-image.component';

registerLocaleData(localeFr, 'fr');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    HomeComponent,
    SearchComponent,
    DemarchesComponent,
    StatsDemarchesComponent,
    CreateDemarcheComponent,
    ListDemarchesComponent,
    DetailsDemarcheComponent,
    MimetypePipe,
    RegisterComponent,
    RegistersellerComponent,
    RegisterdealerComponent,
    RegisterHomeComponent,
    CommonRegisterComponent,
    AccountdashbordComponent,
    OfferComponent,
    CommonOfferComponent,
    OfferhomeComponent,
    CreateofferComponent,
    SellersSaysComponent,
    AccountActivationComponent,
    SecuredImageComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    UiModule,
    BrowserModule,
    HttpClientModule,
    PdfViewerModule,
    NgbTooltipModule,
    UserIdleModule.forRoot({
      idle: 600, 
      timeout: 600, 
      ping: 600
    }),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
      }
    })
  ],
  providers: [
    AuthGuard, AccountPartGuard, AccountProGuard, PublicGuard,
    TokenHeaderInterceptorProviders,
    HttpLoadingInterceptorProviders
  ],
  bootstrap: [AppComponent],
  exports: [SellersSaysComponent, AccountActivationComponent, SecuredImageComponent]
})
export class AppModule { }
