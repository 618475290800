import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { AccountType } from 'src/app/model/AuthResource';

@Injectable()
export class AccountProGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let encodedJwtToken = this.authService.getEncodedJwtToken();    
    if (encodedJwtToken != null && encodedJwtToken.accountType == AccountType.PRO) {
        return true;
    }else {
        this.router.navigate(['/dashboard/home']);
        return false;
    }
  }
}

@Injectable()
export class AccountPartGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let encodedJwtToken = this.authService.getEncodedJwtToken();
    if (encodedJwtToken != null && encodedJwtToken.accountType == AccountType.PART) {
        return true;
    }else {
        this.router.navigate(['/dashboard/home']);
        return false;
    }
  }
}

@Injectable()
export class PublicGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let encodedJwtToken = this.authService.getEncodedJwtToken();
    if (encodedJwtToken == null) {
        return true;
    }else {
        let routePath = encodedJwtToken.accountType == AccountType.PRO ? 'pro' : 'part'
        this.router.navigate([`/account/${routePath}/dashboard`]);
        return false;
    }
  }
}